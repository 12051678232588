/* Load page */
export const CRUD_INIT_PAGE = "CRUD_INIT_PAGE"
export const CRUD_CHANGE_PAGE_FILTER = "CRUD_CHANGE_PAGE_FILTER"
export const CRUD_CHANGE_PAGE_FILTERS = "CRUD_CHANGE_PAGE_FILTERS"
export const CRUD_LOAD_PAGE = "CRUD_LOAD_PAGE"
export const CRUD_LOAD_PAGE_SUCCESS = "CRUD_LOAD_PAGE_SUCCESS"
export const CRUD_LOAD_PAGE_FAIL = "CRUD_LOAD_PAGE_FAIL"

/* Load single item */
export const CRUD_RESET_ITEM = "CRUD_RESET_ITEM"
export const CRUD_LOAD_ITEM = "CRUD_LOAD_ITEM"
export const CRUD_LOAD_ITEM_SUCCESS = "CRUD_LOAD_ITEM_SUCCESS"
export const CRUD_LOAD_ITEM_FAIL = "CRUD_LOAD_ITEM_FAIL"

/* Create an item */
export const CRUD_CREATE_ITEM = "CRUD_CREATE_ITEM"
export const CRUD_CREATE_ITEM_SUCCESS = "CRUD_CREATE_ITEM_SUCCESS"
export const CRUD_CREATE_ITEM_FAIL = "CRUD_CREATE_ITEM_FAIL"

/* Update an item */
export const CRUD_UPDATE_ITEM = "CRUD_UPDATE_ITEM"
export const CRUD_UPDATE_ITEM_SUCCESS = "CRUD_UPDATE_ITEM_SUCCESS"
export const CRUD_UPDATE_ITEM_FAIL = "CRUD_UPDATE_ITEM_FAIL"

/* Delete an item */
export const CRUD_DELETE_ITEM = "CRUD_DELETE_ITEM"
export const CRUD_CANCEL_DELETE_ITEM = "CRUD_CANCEL_DELETE_ITEM"
export const CRUD_REAL_DELETE_ITEM = "CRUD_REAL_DELETE_ITEM"
export const CRUD_REAL_DELETE_ITEM_SUCCESS = "CRUD_REAL_DELETE_ITEM_SUCCESS"
export const CRUD_REAL_DELETE_ITEM_FAIL = "CRUD_REAL_DELETE_ITEM_FAIL"
