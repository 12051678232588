import React from "react"
import PropTypes from "prop-types"
import {fieldShape} from "./shapes";
import {FormGroup, Input, Label} from "reactstrap";
import {withTranslation} from "react-i18next";

function TextField(props) {
    return (
        <FormGroup>
            <Label htmlFor={props.id}>{props.t(props.field.label)}</Label>
            <Input
                id={props.id}
                value={props.value || ''}
                name={props.field.name}
                type={props.field.type}
                required={props.field.required}
                minLength={props.field.minLength}
                maxLength={props.field.maxLength}
            />
        </FormGroup>
    )
}

TextField.propTypes = {
    field: fieldShape.isRequired,
    id: PropTypes.string,
    t: PropTypes.func,
    value: PropTypes.string,
}

export default withTranslation()(TextField)
