import React from "react"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next"
import {Card, CardBody, CardTitle} from "reactstrap"
import {resourceShape} from "../../components/Crud/shapes";
import WalletDataTable from "./WalletDataTable";

function WalletList(props) {
    return (
        <>
            <h4 className={"p-2 mb-3 text-uppercase"}>{props.t('Wallet.WalletList.Title')}</h4>
            <Card>
                <CardTitle><h4 className={"p-4"}>{props.t('Wallet.WalletList.Title')}</h4></CardTitle>
                <CardBody>
                    <WalletDataTable resource={props.resource} />
                </CardBody>
            </Card>
        </>
    )
}

WalletList.propTypes = {
    resource: resourceShape,
    t: PropTypes.func,
}

export default withTranslation()(WalletList)
