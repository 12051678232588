import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  GET_ME_SUCCESS, GET_ME_ERROR, GET_ME, MISSING_TOTP_ERROR, TOTP_LOGIN_ERROR, TOTP_LOGIN_SUCCESS, TOTP_LOGIN,
} from "./actionTypes"
import {getCookie} from "../../../helpers/cookies";

const initialState = {
  error: "",
  loading: false,
  accessToken: localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_ITEM_NAME) ||
      getCookie(process.env.REACT_APP_ACCESS_TOKEN_ITEM_NAME) ||
      null,
  me: null,
  totpLogin: false,
  totpLoginLoading: false,
  totpLoginError: false,
  totpUsername: null,
  totpPassword: null,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
    case GET_ME:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        accessToken: action.payload.response.token,
        totpLogin: false,
      }

      if (action.payload.values.rememberMe) {
        localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN_ITEM_NAME, action.payload.response.token)
      } else {
        document.cookie = `${process.env.REACT_APP_ACCESS_TOKEN_ITEM_NAME}=${action.payload.response.token}; path=/; SameSite=Lax; Secure`
      }

      break
    case LOGOUT_USER:
      state = {
        ...state,
        error: '',
        accessToken: null,
        me: null,
      }

      // Remove access token from both localStorage (remember me) and session cookie (no remember me)
      localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN_ITEM_NAME);
      document.cookie = `${process.env.REACT_APP_ACCESS_TOKEN_ITEM_NAME}=; path=/; SameSite=Lax; Secure; Expires=Thu, 01 Jan 1970 00:00:00 UTC`

      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_ME_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        me: action.payload.user,
      }
      break
    case GET_ME_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        accessToken: null,
        me: null,
      }
      break
    case MISSING_TOTP_ERROR:
      state = {
        ...state,
        totpLogin: true,
        error: null,
        loading: false,
        totpUsername: action.payload.username,
        totpPassword: action.payload.password,
      }
      break
    case TOTP_LOGIN:
      state = { ...state, totpLoginLoading: true, totpLoginError: null }
      break
    case TOTP_LOGIN_SUCCESS:
      state = { ...state, totpLogin: false, totpLoginLoading: false, totpLoginError: null, totpUsername: null, totpPassword: null }
      break
    case TOTP_LOGIN_ERROR:
      state = { ...state, totpLoginLoading: false, totpLoginError: action.payload.error }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
