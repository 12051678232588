export const selectCrudPageInited = name => state => state.crud.initedPages[name] ?? false
export const selectCrudPageFilters = name => state => state.crud.pageFilters[name] ?? null
export const selectCrudPage = name => state => state.crud.pages[name] ?? null
export const selectCrudPageLoading = name => state => state.crud.pagesLoading[name] ?? false
export const selectCrudPageError = name => state => state.crud.pagesErrors[name] ?? null

export const selectCrudItem = name => state => state.crud.currents[name] ?? null
export const selectCrudItemLoading = name => state => state.crud.currentsLoading[name] ?? false
export const selectCrudItemError = name => state => state.crud.currentsErrors[name] ?? null

export const selectCrudCreatedItem = state => state.crud.createdItem
export const selectCrudCreateItemLoading = state => state.crud.createLoading
export const selectCrudCreateItemError = state => state.crud.createError
export const selectCrudCreateItemFieldErrors = state => state.crud.createFieldErrors

export const selectCrudUpdatedItem = state => state.crud.updatedItem
export const selectCrudUpdateItemLoading = state => state.crud.updateLoading
export const selectCrudUpdateItemError = state => state.crud.updateError
export const selectCrudUpdateItemFieldErrors = state => state.crud.updateFieldErrors

export const selectCrudDeletedItem = state => state.crud.deletedItem
export const selectCrudDeleteItemLoading = state => state.crud.deleteLoading
export const selectCrudDeleteItemError = state => state.crud.deleteError
