import React from "react"
import PropTypes from "prop-types"
import {fieldShape} from "./shapes"
import {FormGroup, InputGroup, Label} from "reactstrap";
import Select from "react-select";
import {withTranslation} from "react-i18next";

function PhoneNumberField(props) {
    return (
        <FormGroup>
            <Label htmlFor={props.id}>{props.t(props.field.label)}</Label>

            <InputGroup>
                <Select>

                </Select>
            </InputGroup>
        </FormGroup>
    )
}

PhoneNumberField.propTypes = {
    id: PropTypes.string.isRequired,
    field: fieldShape.isRequired,
    t: PropTypes.func,
}

export default withTranslation()(PhoneNumberField)
