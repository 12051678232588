import React from "react"
import PropTypes from "prop-types"
import {Col, Row} from "reactstrap"
import Field from "./Field"
import {rowsShape} from "./shapes";

function FormRows(props) {
    return props.rows?.map?.((row, rowIndex) => (
        <Row key={rowIndex}>
            {row.map((column, index) => (
                <Col key={index} xs={column.xs || column.col} sm={column.sm} md={column.md} lg={column.lg} xl={column.xl} xxl={column.xxl}>
                    <Field name={column.name} field={column.field} formId={props.formId} />
                </Col>
            ))}
        </Row>
    ))
}

FormRows.propTypes = {
    rows: rowsShape,
    formId: PropTypes.string.isRequired,
}

export default FormRows
