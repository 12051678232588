import React from "react"
import PropTypes from "prop-types"
import DeleteModale from "../../components/Crud/DeleteModale"

function UserDeleteModale(props) {
    return (
        <DeleteModale
            onDeleteSuccess={props.onDeleteSuccess}
            title={'User.UserDeleteModale.Title'}
            body={'User.UserDeleteModale.Body'}
        />
    )
}

UserDeleteModale.propTypes = {
    onDeleteSuccess: PropTypes.func,
}

export default UserDeleteModale
