import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ChangeForgetPassword from "../pages/Authentication/ChangeForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// CRUDs

import User from "../cruds/User"
import Product from "../cruds/Product";
import Wallet from "../cruds/Wallet";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/change-forgotten-password", component: ChangeForgetPassword },
  { path: "/register", component: Register },
]

const crudRoutes = [
  { path: "/users", component: User },
  { path: "/wallets", component: Wallet },
  { path: "/marketplace", component: Product },
]

export { publicRoutes, authProtectedRoutes, crudRoutes }
