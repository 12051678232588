const crcTable = (
    function makeCrcTable() {
        let char;
        let crcTable = [];

        for (let index = 0; index < 256; index++) {
            char = index;

            for (let bit = 0; bit < 8; bit++) {
                char = ((char & 1) ? (0xEDB88320 ^ (char >>> 1)) : (char >>> 1));
            }

            crcTable[index] = char;
        }

        return crcTable;
    }
)();

export function crc32(str) {
    let crc = 0 ^ -1;

    for (let index = 0; index < str.length; index++) {
        crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(index)) & 0xFF];
    }

    return (crc ^ (-1)) >>> 0;
}
