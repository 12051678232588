import axios from "axios"
import {del, get, getBlobAsBase64, patch, post, put} from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Edit profile
const patchMe = config => data => patch(url.PATCH_ME, data, config)
const patchMeAddress = config => data => patch(url.PATCH_ME_ADDRESS, data, config)

// OTP
const getTotpQrCode = config => () => getBlobAsBase64(url.GET_TOTP_QRCODE, config)
const postEnableTotp = config => data => post(url.POST_ENABLE_TOTP, data, config)


// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtRegister,
  patchMe,
  patchMeAddress,
  getTotpQrCode,
  postEnableTotp,
}
