import React from "react"
import PropTypes from "prop-types"
import {initResourceItem, useResource} from "./hooks"
import {Route, Switch} from "react-router-dom"
import DeleteModale from "./DeleteModale"

function Crud(props) {
    const resource = useResource(props.url, props.basePath)
    const DeleteModalImpl = props.components?.deleteModal ?? DeleteModale

    return (
        <>
            <DeleteModalImpl onDeleteSuccess={resource.loadPage} />
            <Switch>
                <Route exact path={props.basePath + "/create"} component={props.components?.form} />
                <Route path={props.basePath + "/:id/edit"} component={({match}) => {
                    const Form = props.components?.form

                    return Form ? <Form id={match.params.id} resource={resource} /> : null
                }} />
                <Route path={props.basePath + "/:id"} component={({match}) => {
                    const Item = props.components?.item;
                    initResourceItem(resource, match.params.id)

                    return Item ? <Item resource={resource} id={match.params.id} /> : null
                }} />
                <Route exact path={props.basePath} component={() => {
                    const List = props.components?.list

                    return List ? <List resource={resource} /> : null
                }} />
            </Switch>
        </>
    );
}

Crud.propTypes = {
    basePath: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    components: PropTypes.shape({
        list: PropTypes.any,
        item: PropTypes.any,
        form: PropTypes.any,
        deleteModal: PropTypes.any,
    }),
}

export default Crud;
