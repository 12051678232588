import React from "react"
import PropTypes from "prop-types"
import Crud from "../../components/Crud"
import UserForm from "./UserForm"
import UserItem from "./UserItem"
import UserDeleteModale from "./UserDeleteModale";
import UserList from "./UserList";

function User(props) {
    return (
        <div className="page-content">
            <Crud
                basePath={props.basePath}
                url="/api/v1/users"
                components={{form: UserForm, item: UserItem, list: UserList, deleteModal: UserDeleteModale}}
            />
        </div>
    )
}

User.propTypes = {
    basePath: PropTypes.string.isRequired,
}

export default User
