import React from "react"
import PropTypes from "prop-types"
import ResourceForm from "../../components/Crud/ResourceForm"
import {resourceShape} from "../../components/Crud/shapes"
import {useSelector} from "react-redux"
import {selectMe} from "../../store/auth/login/selectors"
import {withTranslation} from "react-i18next"

function getAccessibleRoles(roles) {
    if (roles?.includes?.('ROLE_SUPER_ADMIN')) {
        return ['ROLE_INVESTOR', 'ROLE_WMA', 'ROLE_ADMIN', 'ROLE_BUDGET_MANAGER', 'ROLE_SUPER_ADMIN'];
    } else if (roles?.includes?.('ROLE_BUDGET_MANAGER')) {
        return ['ROLE_INVESTOR', 'ROLE_WMA', 'ROLE_ADMIN', 'ROLE_BUDGET_MANAGER'];
    }

    return ['ROLE_INVESTOR', 'ROLE_WMA', 'ROLE_ADMIN'];
}

function UserForm(props) {
    const me = useSelector(selectMe)

    return (
        <ResourceForm
            resource={props.resource}
            rows={[
                [
                    {col: 6, field: {type: 'text', name: 'username', label: 'Username', required: true}},
                    {col: 6, field: {type: 'password', name: 'password', label: 'Password'}},
                ],
                [
                    {col: 6, field: {type: 'email', name: 'email', label: 'Username'}},
                    {col: 6, field: {type: 'phone', name: 'phone', label: 'Phone number'}},
                ],
                [
                    {col: 6, field: {type: 'text', name: 'first_name', label: 'First name'}},
                    {col: 6, field: {type: 'text', name: 'last_name', label: 'Last name'}},
                ],
                [
                    {col: 6, field: {
                        type: 'enum',
                        name: 'roles',
                        multiple: true,
                        label: 'Roles',
                        enum: getAccessibleRoles(me?.roles),
                        enumLabels: {
                            ROLE_INVESTOR: props.t('User.Roles.Investor'),
                            ROLE_WMA: props.t('User.Roles.WMA'),
                            ROLE_ADMIN: props.t('User.Roles.Admin'),
                            ROLE_BUDGET_MANAGER: props.t('User.Roles.BudgetManager'),
                            ROLE_SUPER_ADMIN: props.t('User.Roles.SuperAdmin'),
                        },
                    }},
                    {col: 6, field: {
                        type: 'reference',
                        resource: {
                            path: '/api/v1/users',
                            filters: {
                                roles: ['ROLE_WMA'],
                            }
                        },
                        name: 'owner',
                        label: 'User.Roles.WMA',
                        hide: formData => !formData.get('roles').includes?.('ROLE_INVESTOR'),
                    }},
                ],
            ]}
        />
    )
}

UserForm.propTypes = {
    t: PropTypes.func,
    resource: resourceShape.isRequired,
}

export default withTranslation()(UserForm)
