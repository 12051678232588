import {all, call, fork, put, select, takeEvery} from "redux-saga/effects";
import {
    CRUD_CHANGE_PAGE_FILTER, CRUD_CHANGE_PAGE_FILTERS,
    CRUD_CREATE_ITEM,
    CRUD_LOAD_ITEM,
    CRUD_LOAD_PAGE,
    CRUD_REAL_DELETE_ITEM
} from "./actionTypes";
import {selectAccessToken} from "../auth/login/selectors";
import {
    loadItemFail,
    loadItemSuccess,
    loadPageFail,
    loadPageSuccess,
    realDeleteItemFail,
    realDeleteItemSuccess
} from "./actions";
import {selectCrudDeletedItem, selectCrudPageFilters} from "./selectors";

function* loadPage({name, url}) {
    const accessToken = yield select(selectAccessToken)
    const headers = {
        Authorization: `Bearer ${accessToken}`
    };

    let body = yield select(selectCrudPageFilters(name))
    if (body === null) {
        body = undefined
    } else if (typeof body === 'object') {
        body = JSON.stringify(body)
        headers['Content-Type'] = 'application/json'
    }

    try {
        const response = yield call(fetch, url, {
            method: 'POST',
            headers: headers,
            body: body,
        })

        if (!response.ok) {
            throw new Error('Failed to load page')
        }

        const page = yield call(async () => await response.json())

        yield put(loadPageSuccess(name, page))
    } catch (error) {
        console.log(error)
        yield put(loadPageFail(name, error))
    }
}

function* loadItem({name, url}) {
    const accessToken = yield select(selectAccessToken)

    try {
        const response = yield call(fetch, url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        })

        if (!response.ok) {
            throw new Error('Failed to load item')
        }

        const item = yield call(async () => await response.json())

        yield put(loadItemSuccess(name, item))
    } catch (error) {
        console.log(error)
        yield put(loadItemFail(name, error))
    }
}

function* realDeleteItem({callback}) {
    const accessToken = yield select(selectAccessToken)
    const {url} = yield select(selectCrudDeletedItem)

    try {
        const response = yield call(fetch, url, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        })

        if (!response.ok) {
            throw new Error('Failed to delete item')
        }

        yield put(realDeleteItemSuccess())
        callback?.()
    } catch (error) {
        console.log(error)
        yield put(realDeleteItemFail(error))
    }
}

export function* watchCrudCalls() {
    yield takeEvery(CRUD_LOAD_PAGE, loadPage)
    yield takeEvery(CRUD_LOAD_ITEM, loadItem)
    yield takeEvery(CRUD_REAL_DELETE_ITEM, realDeleteItem)
    // yield takeEvery(CRUD_CREATE_ITEM, createItem)
}

function* crudSaga() {
    yield all([fork(watchCrudCalls)])
}

export default crudSaga
