import {takeEvery, fork, put, all, call, select} from "redux-saga/effects"

// Login Redux States
import {CHANGE_ADDRESS, CHANGE_CONTACT, DISABLE_TOTP, ENABLE_TOTP, LOAD_TOTP_QRCODE} from "./actionTypes"
import {
  loadTotpQrCodeError,
  changeContactSuccess,
  changeContactError,
  loadTotpQrCodeSuccess,
  enableTotpSuccess, enableTotpError, disableTotpSuccess, disableTotpError, changeAddressSuccess, changeAddressError
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTotpQrCode,
  patchMe, patchMeAddress, postEnableTotp,
} from "../../../helpers/fakebackend_helper"

import {selectAccessToken} from "../login/selectors"
import {getMeSuccess, loginSuccess} from "../login/actions";

function* changeContact({ payload }) {
  const accessToken = yield select(selectAccessToken)

  try {
    const response = yield call(patchMe({headers: {Authorization: `Bearer ${accessToken}`}}), {
      username: payload.email,
      email: payload.email,
      previous_password: payload.previous_password,
      password: payload.password || undefined,
    })
    yield put(changeContactSuccess(response.user))
    yield put(getMeSuccess(response.user))
    yield put(loginSuccess({
      response: {token: response.token},
      values: {email: payload.email, password: payload.password},
    }))
  } catch (error) {
    const response = error.response
    const specificErrors = []

    if (response.status === 400) {
      const body = yield response.json();

      body?.errors?.forEach(error => {
        if (error.path === '[previous_password]' && error.code === '83bf92ab-1e34-4325-a27c-06eb61e42ba6') {
          specificErrors.push('invalid_password')
        }

        if (error.path === '[username]' && error.code === 'cd13ae38-f12f-4e4c-b8f5-b5f20b5e7332') {
          specificErrors.push('username_already_taken')
        }
      })
    }

    yield put(changeContactError(error, specificErrors))
  }
}

function* changeAddress({ payload }) {
  const accessToken = yield select(selectAccessToken)

  try {
    const response = yield call(patchMeAddress({headers: {Authorization: `Bearer ${accessToken}`}}), {
      zip_code: payload.zip_code,
      city: payload.city,
      country: payload.country,
      state: payload.state,
      lines: [
        payload.line1,
        payload.line2,
        payload.line3,
      ].filter(line => Boolean(line)),
    })
    yield put(changeAddressSuccess(response))
  } catch (error) {
    yield put(changeAddressError(error))
  }
}

function* doGetTotpQrCode() {
  const accessToken = yield select(selectAccessToken)

  try {
    const content = yield call(getTotpQrCode({headers: {Authorization: `Bearer ${accessToken}`}}))

    yield put(loadTotpQrCodeSuccess(content))
  } catch (error) {
    yield put(loadTotpQrCodeError(error))
  }
}

function* doEnableTotp({ payload }) {
  const accessToken = yield select(selectAccessToken)

  try {
    const newMe = yield call(postEnableTotp({headers: {Authorization: `Bearer ${accessToken}`}}), {
      code: payload.code,
      enabled: true,
    })
    yield put(enableTotpSuccess())
    yield put(getMeSuccess(newMe))
  } catch (error) {
    yield put(enableTotpError(error))
  }
}

function* doDisableTotp({ payload }) {
  const accessToken = yield select(selectAccessToken)

  try {
    const newMe = yield call(postEnableTotp({headers: {Authorization: `Bearer ${accessToken}`}}), {
      code: payload.code,
      enabled: false,
    })
    yield put(disableTotpSuccess())
    yield put(getMeSuccess(newMe))
  } catch (error) {
    yield put(disableTotpError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(CHANGE_CONTACT, changeContact)
  yield takeEvery(CHANGE_ADDRESS, changeAddress)
  yield takeEvery(LOAD_TOTP_QRCODE, doGetTotpQrCode)
  yield takeEvery(ENABLE_TOTP, doEnableTotp)
  yield takeEvery(DISABLE_TOTP, doDisableTotp)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
