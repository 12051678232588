import React from "react"
import PropTypes from "prop-types"
import {resourceShape} from "../../../components/Crud/shapes";
import {useSelector} from "react-redux";

function InvestorUserItem(props) {
    const user = useSelector(props.resource.selectCurrent());

    return (
        <>
            {user.first_name} {user.last_name}
        </>
    )
}

InvestorUserItem.propTypes = {
    resource: resourceShape,
}

export default InvestorUserItem

