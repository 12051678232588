import {SET_FORM_DATA} from "./actionTypes";

export default function form(state = {}, action) {
    switch (action.type) {
        case SET_FORM_DATA:
            return {...state, [action.formId]: action.data}

        default:
            return state
    }
}
