import React from "react"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next";
import {resourceShape} from "../../../components/Crud/shapes";
import WmaUserItem from "./WmaUserItem";
import InvestorUserItem from "./InvestorUserItem";
import AdminUserItem from "./AdminUserItem";
import {initResourceItem} from "../../../components/Crud/hooks";
import {useSelector} from "react-redux";

function UserItem(props) {
    const user = useSelector(props.resource.selectCurrent());

    if (user?.roles?.includes?.('ROLE_WMA')) {
        return <WmaUserItem resource={props.resource} />
    } else if (user?.roles?.includes?.('ROLE_INVESTOR')) {
        return <InvestorUserItem resource={props.resource} />
    } else if (
        user?.roles?.includes?.('ROLE_ADMIN') ||
        user?.roles?.includes?.('ROLE_BUDGET_MANAGER')
    ) {
        return <AdminUserItem resource={props.resource} />
    }

    return null
}

UserItem.propTypes = {
    t: PropTypes.func,
    resource: resourceShape.isRequired,
    id: PropTypes.string.isRequired
}

export default withTranslation()(UserItem)
