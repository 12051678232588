import React from "react"
import Crud from "../../components/Crud"
import WalletList from "./WalletList";

function Wallet(props) {
    return (
        <div className="page-content">
            <Crud
                basePath={'/wallets'}
                url={'/api/v1/wallets'}
                components={{
                    list: WalletList,
                }}
            />
        </div>
    )
}

export default Wallet
