import React from "react"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next"
import {Card, CardBody, CardTitle} from "reactstrap";
import UserDataTable from "./UserDataTable";
import {resourceShape} from "../../components/Crud/shapes";

function UserList(props) {
    return (
        <Card>
            <CardTitle><h4 className={"p-4"}>{props.t('User.UserList.Title')}</h4></CardTitle>
            <CardBody>
                <UserDataTable resource={props.resource} />
            </CardBody>
        </Card>
    )
}

UserList.propTypes = {
    resource: resourceShape,
    t: PropTypes.func,
}

export default withTranslation()(UserList)
