import React from "react"
import PropTypes from "prop-types"
import Crud from "../../components/Crud";
import Marketplace from "./Marketplace";
import ProductItem from "./ProductItem";
import ProductForm from "./ProductForm";

function Product(props) {
    return (
        <>
            <div className="page-content">
                <Crud
                    basePath={props.basePath}
                    url={"/api/v1/products"}
                    components={{list: Marketplace, item: ProductItem, form: ProductForm}}
                />
            </div>
        </>
    )
}

Product.propTypes = {
    basePath: PropTypes.string.isRequired,
}

export default Product
