import {
    CRUD_CANCEL_DELETE_ITEM,
    CRUD_CHANGE_PAGE_FILTER,
    CRUD_CHANGE_PAGE_FILTERS,
    CRUD_CREATE_ITEM, CRUD_CREATE_ITEM_FAIL,
    CRUD_CREATE_ITEM_SUCCESS,
    CRUD_DELETE_ITEM,
    CRUD_INIT_PAGE,
    CRUD_LOAD_ITEM,
    CRUD_LOAD_ITEM_FAIL,
    CRUD_LOAD_ITEM_SUCCESS,
    CRUD_LOAD_PAGE,
    CRUD_LOAD_PAGE_FAIL,
    CRUD_LOAD_PAGE_SUCCESS,
    CRUD_REAL_DELETE_ITEM,
    CRUD_REAL_DELETE_ITEM_FAIL,
    CRUD_REAL_DELETE_ITEM_SUCCESS,
    CRUD_RESET_ITEM, CRUD_UPDATE_ITEM, CRUD_UPDATE_ITEM_FAIL, CRUD_UPDATE_ITEM_SUCCESS
} from "./actionTypes";

export function initPage(name) {
    return {
        type: CRUD_INIT_PAGE,
        name,
    }
}

export function changePageFilters(name, filters) {
    return {
        type: CRUD_CHANGE_PAGE_FILTERS,
        name,
        filters,
    }
}

export function changePageFilter(name, filter, value) {
    return {
        type: CRUD_CHANGE_PAGE_FILTER,
        name,
        filter,
        value,
    }
}


export function loadPage(name, url) {
    return {
        type: CRUD_LOAD_PAGE,
        name,
        url,
    }
}

export function loadPageSuccess(name, page) {
    return {
        type: CRUD_LOAD_PAGE_SUCCESS,
        name,
        page,
    }
}

export function loadPageFail(name, error) {
    return {
        type: CRUD_LOAD_PAGE_FAIL,
        name,
        error,
    }
}

export function resetItem(name) {
    return {
        type: CRUD_RESET_ITEM,
        name,
    }
}

export function loadItem(name, url) {
    return {
        type: CRUD_LOAD_ITEM,
        name,
        url,
    }
}

export function loadItemSuccess(name, item) {
    return {
        type: CRUD_LOAD_ITEM_SUCCESS,
        name,
        item,
    }
}

export function loadItemFail(name, error) {
    return {
        type: CRUD_LOAD_ITEM_FAIL,
        name,
        error,
    }
}

export function createItem(url, item) {
    return {
        type: CRUD_CREATE_ITEM,
        url,
        item,
    }
}

export function createItemSuccess(item) {
    return {
        type: CRUD_CREATE_ITEM_SUCCESS,
        item,
    }
}

export function createItemFail(error) {
    return {
        type: CRUD_CREATE_ITEM_FAIL,
        error,
    }
}

export function updateItem(url, item) {
    return {
        type: CRUD_UPDATE_ITEM,
        url,
        item,
    }
}

export function updateItemSuccess(item) {
    return {
        type: CRUD_UPDATE_ITEM_SUCCESS,
        item,
    }
}

export function updateItemFail(error) {
    return {
        type: CRUD_UPDATE_ITEM_FAIL,
        error,
    }
}

export function deleteItem(url, item) {
    return {
        type: CRUD_DELETE_ITEM,
        url,
        item,
    }
}

export function cancelDeleteItem() {
    return {
        type: CRUD_CANCEL_DELETE_ITEM,
    }
}

export function realDeleteItem(callback) {
    return {
        type: CRUD_REAL_DELETE_ITEM,
        callback,
    }
}


export function realDeleteItemSuccess() {
    return {
        type: CRUD_REAL_DELETE_ITEM_SUCCESS,
    }
}

export function realDeleteItemFail(error) {
    return {
        type: CRUD_REAL_DELETE_ITEM_FAIL,
        error,
    }
}
