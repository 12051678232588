import {
    CRUD_CANCEL_DELETE_ITEM, CRUD_CHANGE_PAGE_FILTER, CRUD_CHANGE_PAGE_FILTERS,
    CRUD_CREATE_ITEM,
    CRUD_CREATE_ITEM_FAIL,
    CRUD_CREATE_ITEM_SUCCESS,
    CRUD_DELETE_ITEM, CRUD_INIT_PAGE,
    CRUD_LOAD_ITEM,
    CRUD_LOAD_ITEM_FAIL,
    CRUD_LOAD_ITEM_SUCCESS,
    CRUD_LOAD_PAGE,
    CRUD_LOAD_PAGE_FAIL,
    CRUD_LOAD_PAGE_SUCCESS,
    CRUD_REAL_DELETE_ITEM,
    CRUD_REAL_DELETE_ITEM_FAIL,
    CRUD_REAL_DELETE_ITEM_SUCCESS, CRUD_RESET_ITEM,
    CRUD_UPDATE_ITEM,
    CRUD_UPDATE_ITEM_FAIL,
    CRUD_UPDATE_ITEM_SUCCESS,
} from "./actionTypes";

const initialState = {
    initedPages: {},
    pages: {},
    pageFilters: {},
    pagesErrors: {},
    pagesLoading: {},
    currents: {},
    currentsErrors: {},
    currentsLoading: {},
    createdItem: null,
    createError: false,
    createFieldErrors: null,
    createLoading: false,
    updatedItem: null,
    updateError: false,
    updateFieldErrors: null,
    updateLoading: false,
    deletedItem: null,
    deleteError: null,
    deleteLoading: false,
}

export default function crud(state = initialState, action) {
    switch (action.type) {
        case CRUD_INIT_PAGE:
            return {
                ...state,
                initedPages: {...state.initedPages, [action.name]: true}
            }

        case CRUD_CHANGE_PAGE_FILTERS:
            return {
                ...state,
                pageFilters: {...state.initedPages, [action.name]: action.filters}
            }

        case CRUD_CHANGE_PAGE_FILTER:
            return {
                ...state,
                pageFilters: {
                    ...state.pageFilters,
                    [action.name]: {
                        ...(state.pageFilters[action.name] || {}),
                        [action.filter]: action.value,
                    }}
            }

        case CRUD_LOAD_PAGE:
            return {
                ...state,
                pageLoading: true,
                pages: {...state.pages, [action.name]: null},
                pagesLoading: {...state.pagesLoading, [action.name]: true},
                pagesErrors: {...state.pagesErrors, [action.name]: null},
            }

        case CRUD_LOAD_PAGE_SUCCESS:
            return {
                ...state,
                pages: {...state.pages, [action.name]: action.page},
                pagesLoading: {...state.pagesLoading, [action.name]: false},
            }

        case CRUD_LOAD_PAGE_FAIL:
            return {
                ...state,
                pagesErrors: {...state.pagesErrors, [action.name]: action.error},
            }

        case CRUD_RESET_ITEM:
            return {
                ...state,
                currents: {...state.currents, [action.name]: null},
            }

        case CRUD_LOAD_ITEM:
            return {
                ...state,
                currentLoading: {...state.currentsLoading, [action.name]: true},
                currents: {...state.currents, [action.name]: null},
            }

        case CRUD_LOAD_ITEM_SUCCESS:
            return {
                ...state,
                currentLoading: {...state.currentsLoading, [action.name]: false},
                currents: {...state.currents, [action.name]: action.item},
            }

        case CRUD_LOAD_ITEM_FAIL:
            return {
                ...state,
                currentLoading: {...state.currentsLoading, [action.name]: false},
                currentsErrors: {...state.currentsErrors, [action.name]: action.error},
            }

        case CRUD_CREATE_ITEM:
            return {...state, createLoading: true, createdItem: null}

        case CRUD_CREATE_ITEM_SUCCESS:
            return {...state, createLoading: false, createdItem: action.item}

        case CRUD_CREATE_ITEM_FAIL:
            return {...state, createLoading: false, createError: action.error, createFieldErrors: action.fieldErrors}

        case CRUD_UPDATE_ITEM:
            return {...state, updateLoading: true, updatedItem: null}

        case CRUD_UPDATE_ITEM_SUCCESS:
            return {...state, updateLoading: false, updatedItem: action.item}

        case CRUD_UPDATE_ITEM_FAIL:
            return {...state, updateLoading: false, updateError: action.error, updateFieldErrors: action.fieldErrors}

        case CRUD_CANCEL_DELETE_ITEM:
            return {...state, deletedItem: null, deleteError: null}

        case CRUD_DELETE_ITEM:
            return {...state, deletedItem: {url: action.url, item: action.item}, deleteError: null}

        case CRUD_REAL_DELETE_ITEM:
            return {...state, deleteLoading: true}

        case CRUD_REAL_DELETE_ITEM_SUCCESS:
            return {...state, deletedItem: null, deleteLoading: false, deleteError: null}

        case CRUD_REAL_DELETE_ITEM_FAIL:
            return {...state, deleteLoading: false, deleteError: action.error}

        default:
            return state
    }
}
