import React from "react"
import PropTypes from "prop-types"
import {fieldsetShape} from "./shapes"
import {withTranslation} from "react-i18next"
import FormFields from "./FormFields";
import FormRows from "./FormRows";

function FormFieldset(props) {
    return (
        <fieldset>
            <legend>{props.t(props.fieldset.legend)}</legend>

            <FormFields formId={props.formId} fields={props.fieldset.fields} />
            <FormRows formId={props.formId} rows={props.fieldset.rows} />
        </fieldset>
    )
}

FormFieldset.propTypes = {
    fieldset: fieldsetShape,
    formId: PropTypes.string.isRequired,
    t: PropTypes.func,
}

export default withTranslation()(FormFieldset)
