import React from "react"
import PropTypes from "prop-types"
import {fieldShape} from "./shapes"
import {FormGroup, Input, Label} from "reactstrap"
import {withTranslation} from "react-i18next";

function IntegerField(props) {
    return (
        <FormGroup>
            <Label htmlFor={props.id}>{props.t(props.field.label)}</Label>

            <Input
                id={props.id}
                type="number"
                name={props.field.name}
                required={props.field.required}
                step={Math.min(1, parseInt(props.field.step))}
                min={typeof props.field.min === 'number' || typeof props.field.min === 'string' ? parseInt(props.field.min) : undefined}
                max={typeof props.field.max === 'number' || typeof props.field.max === 'string' ? parseInt(props.field.max) : undefined}
            />
        </FormGroup>
    )
}

IntegerField.propTypes = {
    id: PropTypes.string.isRequired,
    field: fieldShape.isRequired,
    t: PropTypes.func,
}

export default withTranslation()(IntegerField)
