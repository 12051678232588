import React from "react"
import PropTypes from "prop-types"
import {useDispatch, useSelector} from "react-redux"
import {selectCartProducts} from "../../../../store/cart/selectors"
import {Button, Table} from "reactstrap";
import {withTranslation} from "react-i18next";
import {cartRemoveProduct} from "../../../../store/cart/actions";

function ProductsTable(props) {
    const products = useSelector(selectCartProducts)
    const dispatch = useDispatch()

    if (0 === products.length) {
        return null
    }

    return (
        <>
            <h5 style={{fontWeight:'bold'}}>{props.t('Product.Marketplace.Cart.MySelection')}</h5>
            <div className={"p-2"}>{props.t('Product.Marketplace.Cart.MySelection.Summary')}</div>

            <div className={"table-responsive"}>
                <Table className={"table align-middle mb-0 table-nowrap"}>
                    <tbody>
                    {products.map((product, index) => (
                        <tr key={index}>
                            <td>
                                <div>{product.name}</div>
                                <div>{props.t('Product.Marketplace.Item.Types.' + product.type)}</div>
                            </td>
                            <td style={{width: '.2rem'}}>
                                <Button color={"primary"} outline className={"rounded-circle border-0"}>
                                    <i className={"mdi mdi-eye"} />
                                </Button>
                                <Button color={"danger"} outline onClick={() => dispatch(cartRemoveProduct(product))} className={"rounded-circle border-0"}>
                                    <i className={"bx bxs-trash"} />
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </>
    )
}


ProductsTable.propTypes = {
    t: PropTypes.func,
}

export default withTranslation()(ProductsTable)
