import PropTypes from "prop-types";

export const fieldShape = PropTypes.shape({
    type: PropTypes.oneOf(['text', 'email', 'phone', 'reference', 'enum', 'integer', 'number', 'custom']),
    label: PropTypes.string,
    hide: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    required: PropTypes.bool,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    minCount: PropTypes.number,
    maxCount: PropTypes.number,
    resource: PropTypes.shape({
        path: PropTypes.string,
        filters: PropTypes.object,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    }),
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    multiple: PropTypes.bool,
    unique: PropTypes.bool,
    enum: PropTypes.arrayOf(PropTypes.string),
    enumLabels: PropTypes.objectOf(PropTypes.any),
    render: PropTypes.func,
    renderLabel: PropTypes.func,
    default: PropTypes.any,
})

export const columnShape = PropTypes.shape({
    col: PropTypes.number,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    xxl: PropTypes.number,
    name: PropTypes.string,
    field: fieldShape,
})

export const rowsShape = PropTypes.arrayOf(PropTypes.arrayOf(columnShape));
export const fieldsShape = PropTypes.arrayOf(fieldShape);

export const fieldsetShape = PropTypes.shape({
    legend: PropTypes.string,
    fields: fieldsShape,
    rows: rowsShape,
})
