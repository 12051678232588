import React from "react"
import PropTypes from "prop-types"
import {fieldShape} from "./shapes"
import TextField from "./TextField"
import ReferenceField from "./ReferenceField"
import EnumField from "./EnumField"
import IntegerField from "./IntegerField"
import NumberField from "./NumberField"
import PhoneNumberField from "./PhoneNumberField";

function Field(props) {
    const id = props.formId + '_' + props.name;

    switch (props.field.type) {
        case 'text':
        case 'password':
        case 'email':
            return <TextField id={id} value={props.value} field={props.field} onChange={props.onChange} />

        case 'enum':
            return <EnumField id={id} value={props.value} field={props.field} onChange={props.onChange} />

        case 'reference':
            return <ReferenceField id={id} value={props.value} field={props.field} onChange={props.onChange} />

        case 'integer':
            return <IntegerField id={id} value={props.value} field={props.field} onChange={props.onChange} />

        case 'number':
            return <NumberField id={id} value={props.value} field={props.field} onChange={props.onChange} />

        case 'phone':
            return <PhoneNumberField id={id} value={props.value} field={props.field} onChange={props.onChange} />

        case 'custom':
            return props.field.render(id)

        default:
            throw new Error('Unsupported field type: ' + props.field.type)
    }
}

Field.propTypes = {
    field: fieldShape.isRequired,
    value: PropTypes.any,
    formId: PropTypes.string.isRequired,
    onChange: PropTypes.func,
}

export default Field;
