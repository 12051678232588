import React, {useState} from "react"
import PropTypes from "prop-types"
import {Table, Tbody, Th, Thead, Tr} from "react-super-responsive-table";
import {withTranslation} from "react-i18next";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner} from "reactstrap";
import {columnsShape, rowActionsShape} from "./shapes";

function DataTable(props) {
    const [activeRow, setActiveRow] = useState(null)
    const [openedRowActions, setOpenedRowActions] = useState(false)

    return (
        <Table className={"table table-condensed table-hover" + (props.striped ? ' table-striped' : '')}>
            {props.thead === false || (
                <Thead>
                    <Tr>
                        {props.columns.map((column, index) => (
                            <Th key={index}>{column.translatable === false ? column.title : props.t(column.title)}</Th>
                        ))}
                        <Th />
                    </Tr>
                </Thead>
            )}
            {props.tfoot && (
                <tfoot>
                <Tr>
                    {props.columns.map((column, index) => (
                        <Th key={index}>{column.translatable === false ? column.title : props.t(column.title)}</Th>
                    ))}
                    <Th />
                </Tr>
                </tfoot>
            )}
            <Tbody>
                {props.loading && (
                    <tr>
                        <td colSpan={props.columns.length + 1} className={"text-center p-4"}>
                            <Spinner animation="border" style={{fontSize: '.5rem', width: '7rem', height: '7rem'}} />
                            <div className={"mt-3"}>
                                {props.t('LoadingInProgress')}...
                            </div>
                        </td>
                    </tr>
                )}
                {!props.loading && props?.items?.map?.((item, itemIndex) => (
                    <tr
                        key={itemIndex}
                        onDoubleClick={evt => props.onRowDoubleClick?.(evt, item)}
                    >
                        {props.columns.map((column, index) => (
                            <td key={index}>{typeof column.data === 'string' ? item[column.data] : column.data(item)}</td>
                        ))}
                        <td style={{width: '20px'}}>
                            {Boolean(props.rowActions?.length) && (
                                <Dropdown
                                    isOpen={openedRowActions && activeRow === itemIndex}
                                    toggle={() => {
                                        setActiveRow(activeRow !== null ? null : itemIndex)
                                        setOpenedRowActions(true)
                                    }}
                                    id={props.id + '__dropdown_' + itemIndex}
                                    direction={"start"}
                                    style={{float: "right"}}
                                >
                                    <DropdownToggle className={"border-0 rounded-circle"} outline>
                                        <i className="bx bx-dots-vertical-rounded" />
                                    </DropdownToggle>

                                    <DropdownMenu>
                                        {props.rowActions.map((action, index) => {
                                            if ((typeof action.hide === 'boolean' && action.hide) || action.hide?.(item)) {
                                                return null
                                            }

                                            return (
                                                <DropdownItem key={index} onClick={() => action.callback(item)}
                                                              style={{display: 'flex', alignItems: 'center'}}>
                                                    {typeof action.icon === 'string' ? <i className={action.icon}
                                                                                          style={{marginRight: '10px'}}/> : action.icon}
                                                    {action.translatable === false ? action.title : props.t(action.title)}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            )}
                        </td>
                    </tr>
                ))}
            </Tbody>
        </Table>
    )
}

DataTable.propTypes = {
    id: PropTypes.string.isRequired,
    t: PropTypes.func,
    striped: PropTypes.bool,
    loading: PropTypes.bool,
    pagination: PropTypes.bool,
    thead: PropTypes.bool,
    tfoot: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    columns: columnsShape.isRequired,
    rowActions: rowActionsShape,
    onRowDoubleClick: PropTypes.func,
}

export default withTranslation()(DataTable);
