import React from "react"
import PropTypes from "prop-types"
import {useResource} from "../../../components/Crud/hooks"
import {resourceShape} from "../../../components/Crud/shapes"
import {withTranslation} from "react-i18next"
import UserDataTable from "../UserDataTable"
import {useSelector} from "react-redux";

function WmaUserItem(props) {
    // @todo use prop
    const investors = useResource('/api/v1/users', '/users', 'wma_investors')
    const user = useSelector(props.resource.selectCurrent());

    return (
        <>
            <UserDataTable resource={investors} initFilters={{owner: [user.id]}}/>
        </>
    )
}

WmaUserItem.propTypes = {
    t: PropTypes.func,
    resource: resourceShape,
}

export default withTranslation()(WmaUserItem)
