import React from "react"
import PropTypes from "prop-types"
import {resourceShape} from "../../components/Crud/shapes"
import Pagination from "../../components/Crud/DataTable/Pagination"
import moment from "moment"
import ResourceDataTable from "../../components/Crud/ResourceDataTable"
import {Card, CardBody} from "reactstrap";

function UserDataTable(props) {
    return (
        <>
            <ResourceDataTable
                striped
                resource={props.resource}
                initFilters={{...(props.initFilters || {}), limit: 5}}
                columns={[
                    {title: 'Identifiant', sort: true, data: 'username'},
                    {title: 'First name', sort: true, data: 'first_name'},
                    {title: 'Last name', sort: true, data: 'last_name'},
                    {title: 'Roles', data: row => row.roles?.join?.(', ') || ''},
                    {title: 'Registered at', sort: 'created_at', data: row => moment(row.created_at).format('LLLL')},
                ]}
                rowActions={[
                    {
                        icon: 'mdi mdi-card-account-details',
                        title: 'Impersonate',
                        callback: user => {},
                        hide: user => !(user.roles?.includes?.('ROLE_WMA') || user.roles?.includes?.('ROLE_INVESTOR')),
                    }
                ]}
            />
            <Pagination padding={3} resource={props.resource} />
        </>
    )
}

UserDataTable.propTypes = {
    resource: resourceShape,
    initFilters: PropTypes.object,
}

export default UserDataTable
