import React from "react"
import PropTypes from "prop-types"
import ResourceDataTable from "../../components/Crud/ResourceDataTable"
import {resourceShape} from "../../components/Crud/shapes";
import {withTranslation} from "react-i18next";
import moment from "moment";
import {Badge} from "reactstrap";

function WalletDataTable(props) {
    return (
        <ResourceDataTable
            striped
            resource={props.resource}
            initFilters={{...(props.initFilters || {}), limit: 5}}
            columns={[
                {title: 'Name', sort: true, data: 'name'},
                {title: 'Wallet.WalletDataTable.LastInvestment', sort: true, data: row => row?.last_value ? moment(row?.last_value.timestamp).format('L') : ''},
                {title: 'Wallet.WalletDataTable.Value', data: row =>
                        (row.total_income ? (row.total_income - (row.total_withdraw || 0)) : 0).toString() +
                        row.payment_method.currency.symbol
                },
                {title: 'Wallet.WalletDataTable.RecurringPayment', data: row => row.amount_per_payment.toString() + row.payment_method.currency.symbol},
                {title: 'Wallet.WalletDataTable.InvestmentRecurrence', data: row => props.t('Wallet.RecurringPayment.' + row.payment_recurrence)},
                {
                    title: 'Wallet.WalletDataTable.Status',
                    data: function renderWalletDataTableStatus(row) {
                        if (!row.ends_at || moment(row.ends_at).isAfter()) {
                            return <div className={"badge badge-soft-success"} >
                                {props.t('Wallet.WalletDataTable.Status.InProgress')}
                            </div>
                        }

                        return <div className={"badge badge-soft-dark"} >
                            {props.t('Wallet.WalletDataTable.Status.Closed')}
                        </div>
                    }
                }
            ]}
            rowEdit={false}
            rowDelete={false}
        />
    )
}

WalletDataTable.propTypes = {
    resource: resourceShape,
    initFilters: PropTypes.object,
    t: PropTypes.func,
}

export default withTranslation()(WalletDataTable);
