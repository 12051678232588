import Select from "react-select";
import {FormGroup, Label} from "reactstrap";
import PropTypes from "prop-types";
import {fieldShape} from "./shapes";
import {withTranslation} from "react-i18next";
import React from "react";


function EnumField(props) {
    return (
        <FormGroup>
            <Label htmlFor={props.id}>{props.t(props.field.label)}</Label>
            <Select
                id={props.id}
                isMulti={props.field.multiple}
                required={props.field.required}
                isClearable
                isSearchable
                name={props.field.name}
                options={props.field.enum?.map?.(item => ({
                    [item]: props.field.enumLabels?.[item] || item,
                }))}
            />
        </FormGroup>
    )
}

EnumField.propTypes = {
    field: fieldShape.isRequired,
    id: PropTypes.string,
    t: PropTypes.func,
}

export default withTranslation()(EnumField)
