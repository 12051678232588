import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {useSelector} from "react-redux";
import {selectAccessToken} from "../../store/auth/login/selectors";
import {crc32} from "../../helpers/crc32";
import {fieldsetShape, fieldsShape, rowsShape} from "./shapes";
import FormRows from "./FormRows";
import FormFields from "./FormFields";
import FormFieldset from "./FormFieldset";

function Form(props) {
    const accessToken = useSelector(selectAccessToken)

    // First of all, we must assign a unique ID to each form
    // We use a state to put the computed ID in a cache storage
    // so it is not computed every time the form in rendered
    const [formId, setFormId] = useState(null)

    useEffect(() => {
        if (null === formId) {
            setFormId(crc32(props.action + "\x00" + props.method).toString(16))
        }
    }, [formId])

    // On the first render call, the form ID in not computed yet by the effect
    // We do not generate the rest of the form yet because we need the form ID
    // to create each inputs IDs.
    if (null === formId) {
        return null;
    }

    // The submit function calls the given action with the access token and
    // maps the callback props on the data.
    function submit(event) {
        event.preventDefault()

        let formData = new FormData(event.target.form);

        if (props.onRequest) {
            formData = props.onRequest(formData)
        }

        const promisedRequest = fetch(props.action, {
            method: props.method || 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        })

        if (props.onResponse) {
            promisedRequest.then(response => props.onResponse(response, formData))
        }

        if (props.onError) {
            promisedRequest.catch(props.onError)
        }
    }

    return (
        <form onSubmit={submit}>
            <FormFields formId={formId} fields={props.fields} />
            <FormRows formId={formId} rows={props.rows} />
            {props.fieldSets?.map?.((fieldset, index) => (
                <FormFieldset key={index} formId={formId} fieldset={fieldset} />
            ))}
        </form>
    )
}

Form.propTypes = {
    action: PropTypes.string,
    method: PropTypes.string,
    fields: fieldsShape,
    rows: rowsShape,
    fieldSets: PropTypes.arrayOf(fieldsetShape),
    onResponse: PropTypes.func,
    onError: PropTypes.func,
    onRequest: PropTypes.func,
}

export default Form
