import {useEffect} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {API_BASE_URL} from "../../helpers/url_helper";
import {
    selectCrudItem, selectCrudItemError,
    selectCrudItemLoading,
    selectCrudPage, selectCrudPageError, selectCrudPageFilters, selectCrudPageInited,
    selectCrudPageLoading
} from "../../store/crud/selectors";
import {
    cancelDeleteItem, changePageFilter,
    changePageFilters,
    createItem, deleteItem,
    initPage,
    loadItem,
    loadPage,
    resetItem,
    updateItem
} from "../../store/crud/actions";
import {filterObject, isValidUuid} from "../../helpers/utils";
import {useHistory} from "react-router-dom";

export function initResourcePage(resource, filters) {
    const inited = useSelector(selectCrudPageInited(resource.name))
    const dispatch = useDispatch()

    useEffect(() => {
        if (!inited) {
            const timeout = setTimeout(() => {
                dispatch(changePageFilters(resource.name, filters))
                resource.initPage(0, filters)
            })

            return () => clearTimeout(timeout)
        }
    }, [inited])

    return inited
}

export function initResourceItem(resource, id) {
    const history = useHistory()
    const current = useSelector(resource.selectCurrent())

    useEffect(() => {
        if (!isValidUuid(id)) {
            history.push(resource.basePath);
        }

        if (current?.id !== id) {
            const timeout = setTimeout(() => {
                resource.setCurrent(id)
            }, 200)

            return () => clearTimeout(timeout)
        }
    }, [current, id])
}

export function useResource(apiPath, appPath, name) {
    name = name || apiPath

    const dispatch = useDispatch()

    const addresses = {
        search: API_BASE_URL  + apiPath + '/search',
        all: API_BASE_URL + apiPath,
        one: id => API_BASE_URL + apiPath  + '/' + id,
    }

    function doLoadPage() {
        dispatch(loadPage(name, addresses.search))
    }

    function doInitPage(filters) {
        dispatch(initPage(name))

        if (filters) {
            dispatch(changePageFilters(filters))
        }

        doLoadPage()
    }

    return {
        name,
        basePath: appPath,
        addresses,
        resetCurrent: () => resetItem(name),
        selectCurrent: () => selectCrudItem(name),
        selectCurrentLoading: () => selectCrudItemLoading(name),
        selectCurrentError: selectCrudItemError(name),
        selectPage: () => selectCrudPage(name),
        selectPageFilters: () => selectCrudPageFilters(name),
        selectPageInited: () => selectCrudPageInited(name),
        selectPageLoading: () => selectCrudPageLoading(name),
        selectPageError: () => selectCrudPageError(name),
        setCurrent: id => {
            dispatch(loadItem(name, addresses.one(id)))
        },
        create: form => {
            dispatch(createItem(addresses.all, form))
        },
        update: (id, form) => {
            dispatch(updateItem(addresses.one(id), form))
        },
        delete: item => {
            dispatch(deleteItem(addresses.one(item.id), item))
        },
        cancelDelete: () => {
            dispatch(cancelDeleteItem())
        },
        changeFilter: (field, value) => {
            dispatch(changePageFilter(name, field, value))
        },
        loadPage: doLoadPage,
        initPage: doInitPage,
    }
}
