import React from "react"
import PropTypes from "prop-types"
import {fieldsShape} from "./shapes"
import Field from "./Field";
import {useDispatch, useSelector} from "react-redux";
import {selectFormData} from "../../store/form/selectors";

function FormFields(props) {
    if (typeof props.fields !== 'object') {
        return null
    }

    const dispatch = useDispatch()
    const formData = useSelector(selectFormData(props.formId))

    return Object.keys(props.fields).map(index => (
        <Field
            key={index}
            field={props.fields[index]}
            formId={props.formId}
            value={formData[props.fields[index].name]}
            onChange={newValue => dispatch(setFormData(props.formId, {...formData, [props.fields[index].name]: newValue}))}
        />
    ))
}

FormFields.propTypes = {
    fields: fieldsShape,
    formId: PropTypes.string.isRequired
}

export default FormFields
