import Filters from "./Filters";
import {Col, Row} from "reactstrap";
import Item from "./Item";
import Pagination from "../../../../components/Crud/DataTable/Pagination";
import React from "react";
import {resourceShape} from "../../../../components/Crud/shapes";
import {initResourcePage} from "../../../../components/Crud/hooks";
import {useSelector} from "react-redux";
import {selectCrudPage} from "../../../../store/crud/selectors";

function ItemList(props) {
    initResourcePage(props.resource, {type: ['hesiode']})
    const page = useSelector(props.resource.selectPage())

    return (
        <>
            <Filters resource={props.resource} />
            <Row>
                {page?.items?.map?.((item, index) => (
                    <Col xs={12} sm={6} xxl={4} key={index}>
                        <Item resource={props.resource} item={item} />
                    </Col>
                ))}
            </Row>
            <Pagination resource={props.resource} padding={3} />
        </>
    )
}

ItemList.propTypes = {
    resource: resourceShape,
}

export default ItemList
