import React from "react";
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next";
import {resourceShape} from "../../../components/Crud/shapes";
import {Col, Row} from "reactstrap";
import ItemList from "./ItemList";
import Cart from "./Cart";

function Marketplace(props) {
    return (
        <>
            <h2>{props.t('Product.Marketplace.Title')}</h2>
            <Row>
                <Col xs={12} md={8}>
                    <ItemList resource={props.resource} />
                </Col>
                <Col xs={12} md={4}>
                    <Cart resource={props.resource} />
                </Col>
            </Row>
        </>
    )
}

Marketplace.propTypes = {
    resource: resourceShape.isRequired,
    t: PropTypes.func,
}

export default withTranslation()(Marketplace)
