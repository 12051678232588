import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {columnsShape, rowActionsShape} from "../DataTable/shapes"
import {resourceShape} from "../shapes"
import DataTable from "../DataTable"
import {useDispatch, useSelector} from "react-redux";
import {deleteItem} from "../../../store/crud/actions";
import {useHistory} from "react-router-dom";
import {initResourcePage} from "../hooks";
import {selectCrudPage, selectCrudPageLoading} from "../../../store/crud/selectors";

function ResourceDataTable(props) {
    const history = useHistory()
    const page = useSelector(props.resource.selectPage())
    const pageLoading = useSelector(props.resource.selectPageLoading())

    initResourcePage(props.resource, props.initFilters)

    return (
        <DataTable
            id={'resource_datatable_' + props.resource.name}
            striped={props.striped}
            loading={pageLoading}
            pagination={props.pagination}
            thead={props.thead}
            tfoot={props.tfoot}
            columns={props.columns}
            items={page?.items}
            onRowDoubleClick={
                false !== props.rowDoubleClick ?
                    (evt, row) => history.push(props.resource.basePath + '/' + row.id) :
                    undefined
            }
            rowActions={[
                ...(props.rowActions || []),
                ...(props.defaultActions === false || props.rowDetails === false ? [] : [{
                    icon: 'mdi mdi-eye',
                    title: 'Details',
                    callback: row => history.push(props.resource.basePath + '/' + row.id),
                }]),
                ...(props.defaultActions === false || props.rowEdit === false ? [] : [{
                    icon: 'bx bx-edit',
                    title: 'Edit',
                    callback: row => history.push(props.resource.basePath + '/' + row.id + '/edit'),
                }]),
                ...(props.defaultActions === false || props.rowDelete === false ? [] : [{
                    icon: 'bx bx-trash',
                    title: 'Delete',
                    callback: props.resource.delete,
                }]),
            ]}
        />
    )
}

ResourceDataTable.propTypes = {
    resource: resourceShape.isRequired,
    striped: PropTypes.bool,
    pagination: PropTypes.bool,
    thead: PropTypes.bool,
    tfoot: PropTypes.bool,
    columns: columnsShape.isRequired,
    rowActions: rowActionsShape,
    defaultActions: PropTypes.bool,
    rowDetails: PropTypes.bool,
    rowEdit: PropTypes.bool,
    rowDelete: PropTypes.bool,
    rowDoubleClick: PropTypes.bool,
    initFilters: PropTypes.object,
}

export default ResourceDataTable
