import React from "react"
import PropTypes from "prop-types"
import {resourceShape} from "../../../components/Crud/shapes";

function AdminUserItem(props) {
    return (
        <></>
    )
}

AdminUserItem.propTypes = {
    resource: resourceShape,
}

export default AdminUserItem
