import React from "react"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next";
import Select from "react-select";
import {FormGroup, Label} from "reactstrap";
import {fieldShape} from "./shapes";
import {initResourcePage, useResource} from "../Crud/hooks";
import {useSelector} from "react-redux";
import {selectCrudPage, selectCrudPageFilters, selectCrudPageLoading} from "../../store/crud/selectors";

function ReferenceField(props) {
    const resource = useResource(props.field.resource.path, '', props.id)
    const page = useSelector(resource.selectPage())
    const pageLoading = useSelector(resource.selectPageLoading())

    initResourcePage(resource, props.field.resource.filters)

    function formatLabel(item) {
        if (typeof props.field.resource.label === 'string') {
            return item[props.field.resource.label]
        } else if (props.field.resource.label) {
            return props.field.resource.label(item)
        }

        return 'No label'
    }

    return (
        <FormGroup>
            {props.field.multiple ?
                props.value.map((valueItem, index) =>
                    <input key={index} name={this.props.field.name + '[' + index.toString() + ']'} value={valueItem}/>
                ) :
                <input type="hidden" name={props.field.name} value={props.value} />
            }

            <Label htmlFor={props.id}>{props.t(props.field.label)}</Label>

            <Select
                id={props.id}
                isMulti={props.field.multiple}
                isSearchable
                isLoading={pageLoading}
                options={page?.items?.map(item => ({value: item.id, label: props.field.renderLabel?.(item) || item.id }))}
                onChange={option => option}
                onInputChange={newValue => resource.changeFilter(props.field.name, newValue)}
            />
        </FormGroup>
    )
}

ReferenceField.propTypes = {
    field: fieldShape,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    id: PropTypes.string,
    t: PropTypes.func,
}

export default withTranslation()(ReferenceField)
