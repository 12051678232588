import React from "react"
import PropTypes from "prop-types"
import {fieldShape} from "./shapes"
import {FormGroup, Input, Label} from "reactstrap"
import {withTranslation} from "react-i18next";

function NumberField(props) {
    return (
        <FormGroup>
            <Label htmlFor={props.id}>{props.t(props.field.label)}</Label>

            <Input
                id={props.id}
                type="number"
                name={props.field.name}
                required={props.field.required}
                step={props.field.step || .1}
                min={typeof props.field.min === 'number' || typeof props.field.min === 'string' ? parseFloat(props.field.min) : undefined}
                max={typeof props.field.max === 'number' || typeof props.field.max === 'string' ? parseFloat(props.field.max) : undefined}
            />
        </FormGroup>
    )
}

NumberField.propTypes = {
    id: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    field: fieldShape.isRequired,
}

export default withTranslation()(NumberField)
