import React, {useState} from "react"
import PropTypes from "prop-types";
import {resourceShape} from "../shapes";
import Form from "../../Form";
import {columnShape, fieldsetShape, fieldShape, fieldsShape, rowsShape} from "../../Form/shapes";
import {useSelector} from "react-redux";

function ResourceForm(props) {
    const current = useSelector(props.resource.selectCurrent())
    const [formError, setFormError] = useState(null)
    const [fieldErrors, setFieldErrors] = useState({})

    return (
        <Form
            action={
                current ?
                    props.resource.addresses.one(current.id) :
                    props.resource.addresses.all
            }
            method={current ? 'PATCH' : 'POST'}
            onResponse={async (response, formData) => {
                if (response.statusCode === 400) {
                    const body = await response.json()

                    setFieldErrors(body?.errors?.reduce?.((acc, error) => {
                        acc[error.path] = error
                    }, {}) ?? {})

                    throw new Error('One or more fields are invalid')
                } else if (!response.ok) {
                    throw new Error('Failed to call API')
                }


            }}
            onError={error => setFormError(error ?? 'An unknown error happened')}
            fields={props.fields}
            rows={props.rows}
            fieldSets={props.fieldSets}
            onRequest={props.onRequest}
        />
    )
}

ResourceForm.propTypes = {
    resource: resourceShape.isRequired,
    fields: fieldsShape,
    rows: rowsShape,
    fieldSets: PropTypes.arrayOf(fieldsetShape),
    onRequest: PropTypes.func,
}

export default ResourceForm
