import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import ChangeForgottenPassword from "./auth/changeforgetpwd/reducer";

// Crud
import cart from "./cart/reducer"

// Crud
import crud from "./crud/reducer"

// Form
import form from "./form/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ChangeForgottenPassword,
  ForgetPassword,
  Profile,
  cart,
  crud,
  form,
})

export default rootReducer
