import React, {useEffect} from "react"
import PropTypes from "prop-types"
import {Card, CardBody, Col, FormGroup, Input, Row} from "reactstrap"
import {withTranslation} from "react-i18next"
import Select from "react-select"
import {useSelector} from "react-redux";
import {resourceShape} from "../../../../components/Crud/shapes";

function Filters(props) {
    const filters = useSelector(props.resource.selectPageFilters())

    const managerOptions = [
        {value: 'hesiode', label: props.t('Product.Marketplace.Item.Types.hesiode')},
        {value: 'wma', label: props.t('Product.Marketplace.Item.Types.wma')},
        {value: 'custom', label: props.t('Product.Marketplace.Item.Types.custom')},
    ]

    useEffect(() => {
        if (filters?.search_term !== undefined) {
            const timeout = setTimeout(props.resource.loadPage, 300);

            return () => clearTimeout(timeout)
        }
    }, [filters?.search_term])

    return (
        <Card>
            <CardBody style={{paddingBottom: 0}}>
                <Row>
                    <form onSubmit={evt => {evt.preventDefault()}}>
                        <Row>
                            <Col xs={4}>
                                <FormGroup>
                                    <Input
                                        type="text"
                                        value={filters?.search_term || ''}
                                        onChange={evt => props.resource.changeFilter('search_term', evt.target.value)}
                                        className="rounded-pill"
                                        placeholder={props.t('Product.Marketplace.Filters.SearchTerm')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>

                            </Col>
                            <Col xs={4}>

                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <b>{props.t('Marketplace.Filters.Manager')}</b>

                                    <Select
                                        onChange={options => {
                                            props.resource.changeFilter('type', options.map(option => option.value))
                                            props.resource.loadPage()
                                        }}
                                        value={managerOptions.filter(option => filters?.type?.includes(option.value))}
                                        options={managerOptions}
                                        isMulti={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </form>
                </Row>
            </CardBody>
        </Card>
    )
}

Filters.propTypes = {
    t: PropTypes.func,
    resource: resourceShape.isRequired,
}

export default withTranslation()(Filters)
