import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects"

// Login Redux States
import {GET_ME, LOGIN_USER, LOGOUT_USER, TOTP_LOGIN} from "./actionTypes"
import {
  apiError,
  getMe,
  getMeError,
  getMeSuccess,
  loginSuccess,
  logoutUserSuccess,
  missingTotpError, totpLoginError,
  totpLoginSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {authenticate, me} from "../../../helpers/backend_helper";
import {selectAccessToken, selectTotpPassword, selectTotpUsername} from "./selectors";

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { values, history } }) {
  try {
    const response = yield call(authenticate, {
      username: values.email,
      password: values.password,
      grant_type: 'password',
    })
    yield put(loginSuccess({response, values}))
    yield put(getMe(history));
  } catch (error) {
    const response = error.response;

    if (response.status === 400) {
      const body = yield response.json();
      console.log(body)

      if (body?.errors?.length === 1 &&
          body.errors[0].path === '[totp_code]' &&
          body.errors[0].code === '2fa2158c-2a7f-484b-98aa-975522539ff8'
      ) {
        yield put(missingTotpError(values.email, values.password))
      } else {
        yield put(apiError(error.message))
      }
    } else {
      yield put(apiError(error.message))
    }
  }
}

function* doTotpLogin({ payload: { code, history } }) {
  const username = yield select(selectTotpUsername);
  const password = yield select(selectTotpPassword);

  try {
    const response = yield call(authenticate, {
      username,
      password,
      grant_type: 'password',
      totp_code: code,
    })
    yield put(totpLoginSuccess())
    yield put(loginSuccess({response, values: {username, password}}))
    yield put(getMe(history));
  } catch (error) {
    yield put(totpLoginError(error.message))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* doGetMe({ payload: { history, destination } }) {
  const accessToken = yield select(selectAccessToken)

  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      throw Error('Firebase not supported')
    } else {
      const response = yield call(me, accessToken)

      if (!response.roles?.includes?.('ROLE_ADMIN') &&
          !response.roles?.includes?.('ROLE_SUPER_ADMIN') &&
          !response.roles?.includes?.('ROLE_BUDGET_MANAGER')
      ) {
        yield put(getMeError('Only hesiode admin may log in this app'))
      } else {
        yield put(getMeSuccess(response))
      }
    }
    history.push(destination || "/dashboard")
  } catch (error) {
    yield put(getMeError(error.message))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(TOTP_LOGIN, doTotpLogin)
  yield takeLatest(GET_ME, doGetMe)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
